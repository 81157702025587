import React, {  useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import closeIcon from "../../images/close-x.svg";

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

const UserCommentForm = ({ title, subtitle, onClose = false, show }) => {
  return show ? (
    <div className="bg-white rounded-[10px] pt-10 pb-20 w-full relative">
      {onClose !== false && (
        <button className="absolute top-2 right-2 " onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
      )}
      <div className="max-w-[610px] space-y-5 mx-auto text-center">
        <div>
          <img src="/search-noreults.svg" alt="no reults" className="mx-auto" />
        </div>
        <div className="space-y-2.5 text-[#3D4A5C]">
          <div className="text-[34px] leading-[130%] trancking-[-1.02px] font-readexpro ">
            {title}
          </div>
          <div className="text-[24px] leading-[130%] trancking-[-0.72px] font-readexpro ">
            {subtitle}
          </div>
        </div>
        <div className="max-w-[320px] mx-auto ">
          <AskUsForm />
        </div>
      </div>
    </div>
  ) : (
    false
  );
};

const AskUsForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required Field";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    // if (!values.first) {
    //   errors.first = "Required Field";
    // }

    // if (!values.last) {
    //   errors.last = "Required Field";
    // }

    if (!values.message) {
      errors.last = "Required Field";
    }

    return errors;
  };

  const submitData = (values, { setSubmitting }) => {
    // console.log( values)
    console.log( 'AskUsForm' , values)

    const formGuid = "9e74014a-39e1-403a-8bb7-e99c60f7f3f5";
    const portalId = "26165421";

    //const hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //console.log( document.location.href )
    const data = {
      fields: [
        {
          name: "email",
          value: values.email,
        },
        {
          name: "message",
          value: values.message,
        },
      ],
    };

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
    //console.log( url)

    axios.post(url, data, config).then(
      (response) => {
        console.log(response);
        setSubmitting(false);
        setIsSubmitted(true);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  if (isSubmitted) {
    return <div>Thank you.</div>;
  }

  return (
    <div>
      {/* <h1>Any place in your app!</h1> */}
      <Formik
        initialValues={{ email: "", /*first: "", last: "",*/ message: "" }}
        validate={validate}
        onSubmit={submitData}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="block space-y-4 ">
              <div className=" ">
                <div className="">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="p-2.5 border w-full "
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="px-2 text-[#ff0000]"
                  />
                </div>
              </div>
              <div className=" ">
                <div className="">
                  <Field
                    as="textarea"
                    name="message"
                    placeholder="How can we help?"
                    className="p-2.5 border w-full"
                    rows="3"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="px-2 text-[#ff0000]"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="text-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="border rounded-full py-3 px-4 bg-[#455468] text-white text-sm font-semibold text-center"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};


export {AskUsForm, UserCommentForm};
