import React from "react";
import { Link } from "gatsby";

const beta = "b9be6127-218b-5993-ad22-4bceec596732";

const NodeIntegration = ({ data, content, ...props }) => {
  const { title, path, coming_soon } = data;
  //console.log(props)

  const image = {
    ...data.image,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  const tags = data.relationships.tags;
  return (
    <div>
      <Link to={path.alias} className="">
        <div className="relative border bg-white rounded-xl pt-[30px] px-[30px] pb-[22px] flex flex-col items-between gap-4 h-full overflow-hidden">
          {coming_soon && <div className="absolute rotate-45 bg-gray-200 top-[30px] right-[-40px] text-[#8E897A] text-xs font-medium bg-[#F9F8F7] border-y border-[#E3E1DA] py-1 px-10 ">
            Coming soon
          </div>}
          <div className="space-y-3">
            <div className="w-[60px] h-[60px]  flex flex-col items-center justify-around ">
              <img {...image} alt={image.alt} className="w-full max-h-[60px] " />
            </div>
            <div className="text-sm font-medium leading-[21px] text-[#3D434C]">{title}</div>
          </div>
          <NodeIntegration.Tags tags={tags} />
        </div>
      </Link>
    </div>
  );
};

NodeIntegration.Tags = ({ tags }) => {
  const tags1 = tags.filter((t) => t.id === beta);
  //const tags2 = tags.filter((t) => t.id !== beta);

  //const tags3 = [...tags1, ...tags2];
  const tags3 = tags1;

  return (
    <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 text-[11px] text-[#1B4DFF] ">
      {tags3.map((tag, index) => (
        <NodeIntegration.Tag tag={tag} key={index} />
      ))}
    </div>
  );
};

NodeIntegration.Tag = ({ tag }) => {
  const className =
    tag.id === beta
      ? "text-[#16A077] bg-[#EDFBF7] rounded-[4px] px-2"
      : "bg-[#F2F5FF] rounded-[4px] px-2";

  return <div className={className}>{tag.name}</div>;
};

export default NodeIntegration;
