import React from "react";
//import { Link } from "gatsby";


const ParagraphCard = ({ data, context, onClick, ...props }) => {
  
  //  console.log( data )
    
  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div
      className="flex flex-col justify-between mx-auto p-[30px]  text-[#3D434C] bg-[#F3F2EF] rounded-[12px] border border-[#F4F5F6] w-full space-y-3 "
      style={{
        boxShadow: "0px 8px 11px -4px #1A1D210A , 0px 20px 24px -4px #1A1D210A",
      }}
    >
      <div className="space-y-3">
      <div className="flex flex-row items-center justify-start gap-x-[20px]">
        <div className="">
          <img  src={`${image.src}`} alt={`${image.alt}`} />
        </div>
        <h3
          className="font-semibold text-xl  leading-[24px] tracking-[-0.03em] "
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
      </div>

      <div className="font-sm leading-[21px] tracking-[-0.03em] max-w-[740px] text-[#88909F]">
        {data.description}
      </div>
      </div>

      {/* <div className="">
        <span className="py-1 px-3 rounded-[4px]  bg-[#F4F5F6]">
          <button className="text-[#1B4DFF] text-[15px] font-medium leading-[20px]" id={data.id} onClick={onClick}> 
          {data.link.title} -->
          </button>
          
        </span>
      </div> */}
    </div>
  );
};


export default ParagraphCard;
