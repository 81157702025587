import React, { useState, useMemo, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import {
  HowItWorksBlock,
  WhatIsItBlock,
} from "../components/marketplace/blocks";
import SearchInput from "../components/marketplace/search-input";
import {
  CheckboxGroup,
  CheckboxIntegrations,
} from "../components/marketplace/checkboxes";

import { intersect } from "../util/marketplace";
import RequestNew from "../components/buttons/request-new";
import ParagraphCard from "../components/paragraphs/card";
import NodeIntegration from "../components/node/integration";

// import Pagination from "../components/marketplace/pagination";

const buildQueryString = (s) => {
  const components = [];

  const values = s.tags
    .map((t) => t.name.toLowerCase())
    .map((t) => encodeURIComponent(t));

  if (values.length > 0) {
    components.push(`tags=${values.join(",")}`);
  }

  if (s.query !== "") {
    components.push(`${"text"}=${s.query}`);
  }

  const q = "?" + components.join("&");
  return q;
};


function addSlash(path) {
  // Check if the string ends with a slash using endsWith()
  if ( path && !path.endsWith('/')) {
    // If not, add a slash using concatenation
    return path + '/';
  }
  // If it already has a slash, return the original string
  return path;
}

const createBase = (integrations, terms) => {
  const base = {
    integrations: [],
    terms: [],
  };

  base.integrations = integrations
    .map((n) => {
      const elements = [];
      elements.push(n.title.trim());
      elements.push(n.label);
      if (n?.body?.processed) {
        elements.push(n.body.processed.replace(/(<([^>]+)>)/gi, ""));
      }
      for (const x in n.relationships.tags) {
        const term = n.relationships.tags[x];
        elements.push(term.name);
      }
      return {
        
        ...n,
        path:{ alias: addSlash(n.path.alias)},
        title: n.title.trim(),
        text: elements.join(" ").toLowerCase(),
        
      };
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  base.terms = terms
    .filter(
      (term) =>
        ![
          "b9be6127-218b-5993-ad22-4bceec596732" /*, '77e09009-6db8-56a6-ba1d-21be12316a93'*/,
        ].includes(term.id)
    )
    .filter((term) => {
      const ids = [];
      for (const n in integrations) {
        const node = integrations[n];

        for (const k in node.relationships.tags) {
          const tag = node.relationships.tags[k];
          ids.push(tag.id);
        }
      }
      const ids2 = [...new Set([...ids])];

      return ids2.includes(term.id);
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  return base;
};

const filterIntegrations = (integrations, userInput) => {
  let output = integrations;

  if (userInput.tags.length > 0) {
    const tagsIds = userInput.tags.map((v) => v.id);
    output = output.filter((node) => {
      const ids = node.relationships.tags.map((t) => t.id);

      if (tagsIds.length === 0) {
        return true;
      }

      const inter = intersect(ids, tagsIds);
      return inter.length > 0;
    });
  }

  if (userInput.query !== "") {
    const lower = userInput.query.toLowerCase();
    output = output.filter((t) => {
      return t.text.includes(lower);
    });
  }

  return output;
};

function IntegrationsPage({
  data: { node, terms, nodes },
  location,
  path,
  ...props
}) {
  const { hero, /*sections,*/ cards_section } = node.relationships;

  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );

  const [userInput, setUserInput] = useState({
    tags: [],
    query: "",
    all: true,
  });

  const base = useMemo(
    () => createBase(nodes.nodes, terms.nodes),
    [nodes.nodes, terms.nodes]
  );

  const handleAllCheckboxChange = (item, checked) => {
    const s = { ...userInput };
    s.tags = [];
    s.all = true;
    setUserInput(s);

    const url = buildQueryString(s);
    window.history.pushState({}, "", url);
  };

  const handleCheckboxChange = (item, checked) => {
    const s = { ...userInput };

    if (checked) {
      s.tags.push(item);
    } else {
      s.tags = s.tags.filter((e) => e.id !== item.id);
    }
    //}

    s.all = s.tags.length === 0;

    setUserInput(s);

    const url = buildQueryString(s);
    window.history.pushState({}, "", url);
  };

  const handleTextInputChange = (e) => {
    const s = { ...userInput, query: e.target.value };
    setUserInput(s);

    const url = buildQueryString(s);
    window.history.pushState({}, "", url);
  };

  const handleBlockClick = (e) => {
    const spreadsheet = "026b8528-31d6-5943-882f-6c3bb3345b34";
    const database = "657f4576-e5e5-5aa9-b38b-d87d1f5c299f";

    const s = { ...userInput, query: "" };

    switch (e.target.id) {
      //apps
      case "0ce9fd9f-d231-54f0-a430-aa397bc793ea":
        s.tags = base.terms
          .filter((t) => t.id !== spreadsheet)
          .filter((t) => t.id !== database);
        break;
      //spreadsheet
      case "1f5d0f0f-87ed-5fef-9f0b-3ada56402271":
        s.tags = [base.terms.find((t) => t.id === spreadsheet)];
        break;
      //database
      case "cd103149-2ce4-5e05-9d35-8f7064b55302":
        s.tags = [base.terms.find((t) => t.id === database)];
        break;

      default:
        //console.log(e.target.id);
        //console.log(cards_section.relationships.cards);
        break;
    }

    s.all = s.tags.length === 0;
    setUserInput(s);

    const url = buildQueryString(s);
    window.history.pushState({}, "", url);
  };
  
  
  const integrations = filterIntegrations(base.integrations, userInput);

  useEffect(() => {
    const initInput = {
      tags: [],
      query: "",
      all: true,
    };

    const searchParams = new URLSearchParams(location.search);

    //for (let voc of vocsNames) {
    if (searchParams.has("tags")) {
      const termsNames = searchParams.get("tags").split(",");

      termsNames.map((t) => {
        const tt = terms.nodes.find((c) => c.name.toLowerCase() === t);

        if (tt) {
          initInput.tags.push(tt);
        }
        return null;
      });
    }
    //}

    if (searchParams.has("text")) {
      initInput.query = searchParams.get("text");
    }
    initInput.all = initInput.tags.length === 0;
    setUserInput(initInput);
  }, [location, terms]);

  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <div className="h-[70px]"></div>
      <Hero {...hero}  />
      <Blocks
        items={cards_section.relationships.cards}
        onBlockClick={handleBlockClick}
      />

      <Integrations>
        <div className="min-w-[300px] border-r border-[#E9EFF6] px-4 lg:px-6 py-2.5 ">
          <IntegrationsTerms
            terms={base.terms}
            onCheckboxChange={handleCheckboxChange}
            values={userInput.tags}
            onCheckboxAllChange={handleAllCheckboxChange}
            allValue={userInput.all}
          />
        </div>
        <div className="w-full px-4 lg:pl-8 lg:pr-0">
          <IntegrationsNodes
            nodes={integrations}
            sections={base.terms}
            selected={userInput.tags}
            search={
              <SearchInput
                onChange={handleTextInputChange}
                value={userInput.query}
              />
            }
          />
        </div>
      </Integrations>

      <div className=" max-w-screen-xl px-6 lg:px-[80px] mx-auto space-y-16 lg:space-y-[142px] my-16 ">
        <HowItWorksBlock className="py-[30px] space-y-6" />
      </div>

      <div className=" max-w-screen-xl px-6 lg:px-[80px] mx-auto space-y-16 lg:space-y-[142px] my-16 ">
        <WhatIsItBlock />
      </div>
    </Layout>
  );
}


const Hero = ({
  title,
  description_html,
  ...props
}) => {
  //console.log( props )
  return (
    <section
      className=""
      
    >
      <div className=" max-w-[14400px] mx-auto pt-12 lg:pt-[74px] pb-[84px] px-6 lg:px-0 bg-no-repeat bg-top"
      style={{
        backgroundImage:
          "url('/background/background-image-hero-integrations-2.png')",
      }}
      >
      <div className=" space-y-[102px] ">
        <div className="space-y-8">
          <div className=" text-center">
            <div className="inline-block rounded-[4px] text-[#1B4DFF] bg-[#F2F5FF] py-[7px] px-[18px] leading-[20px] font-medium">
              {props.label}
            </div>
          </div>
          <div className="">
            <h1
              className="text-center  text-[40px] lg:text-[60px] font-medium leading-[44px] lg:leading-[66px] lg:tracking-[-0.03em] text-[#3D434C] max-w-[900px] mx-auto"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <div
            className="text-center text-[20px] leading-[32px] text-[#3D434C] max-w-[600px] mx-auto"
            dangerouslySetInnerHTML={{ __html: description_html.processed }}
          />
        </div>
      </div>
      </div>
    </section>
  );
};


// const Hero3 = ({
//   title,
//   description,
//   description_html,
//   sections,
//   path,
//   ...props
// }) => {
//   //console.log( props )
//   return (
//     <section
//       className="mt-12 lg:mt-[74px] pb-[84px] px-6 lg:px-0 bg-cover border"
//       style={{
//         backgroundImage:
//           "url('/background/background-image-hero-integrations.png')",
//       }}
//     >
//       <div className="max-w-screen-xl mx-auto space-y-[102px]">
//         <div className="space-y-8">
//           <div className=" text-center">
//             <div className="inline-block rounded-[4px] text-[#1B4DFF] bg-[#F2F5FF] py-[7px] px-[18px] leading-[20px] font-medium">
//               {props.label}
//             </div>
//           </div>
//           <div className="">
//             <h1
//               className="text-center  text-[40px] lg:text-[60px] font-medium leading-[44px] lg:leading-[66px] lg:tracking-[-0.03em] text-[#3D434C] max-w-[900px] mx-auto"
//               dangerouslySetInnerHTML={{ __html: title }}
//             />
//           </div>

//           <div
//             className="text-center text-[20px] leading-[32px] text-[#3D434C] max-w-[600px] mx-auto"
//             dangerouslySetInnerHTML={{ __html: description_html.processed }}
//           />
//         </div>
//       </div>
//     </section>
//   );
// };

const Blocks = ({ items, onBlockClick, ...props }) => {
  const cards = items.map((card, index) => (
    <Component
      data={card}
      context="integrations"
      key={index}
      onClick={onBlockClick}
    />
  ));
  return (
    <section className="relative px-6 lg:px-0">
      <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-3  gap-[30px] ">
        {cards}
      </div>
      <div className="hidden lg:block absolute bottom-0 left-0 right-0 h-[50%] bg-[#F9FAFA] -z-10"></div>
    </section>
  );
};

const Integrations = ({ children }) => {
  return (
    <section className="pb-32 pt-[108px] bg-[#F9FAFA]  ">
      <div className="max-w-screen-xl mx-auto ">
        <div className="flex flex-col lg:flex-row justify-between">
          {children}
        </div>
      </div>
    </section>
  );
};

const IntegrationsTerms = ({
  terms,
  values,
  onCheckboxChange,
  allValue,
  onCheckboxAllChange,
}) => {
  const ids = [
    "b9be6127-218b-5993-ad22-4bceec596732",
    "77e09009-6db8-56a6-ba1d-21be12316a93",
  ];
  const terms0 = terms.filter((term) => !ids.includes(term.id));

  const all = {
    name: "All",
    id: "all",
  };

  return (
    <div className="text-left space-y-4">
      <div>
        <h3 className="text-sm leadin-[20px] font-medium text-[#3D434C]">
          {"Category"}
        </h3>
      </div>
      <div className="">
        <CheckboxIntegrations
          item={all}
          checked={allValue}
          onChange={onCheckboxAllChange}
        />
      </div>

      <CheckboxGroup
        //title={"Category"}
        items={terms0}
        onChange={onCheckboxChange}
        values={values}
        CheckboxComp={CheckboxIntegrations}
        //TitleComp={CheckboxGroupTitleComp}
        limit={20}
      />
    </div>
  );
};

/**
 *
 * we have sections like popular, other, cats
 *
 */
const IntegrationsNodes = ({ nodes, sections, search, selected }) => {
  //console.log( sections)
  //console.log( selected)

  const all = selected.length === 0;

  const popular = sections.find(
    (s) => s.id === "77e09009-6db8-56a6-ba1d-21be12316a93"
  );
  //console.log( popular )
  // const secs = []
  // if( all ){
  //   secs.push()
  // }

  return (
    <div className="text-left space-y-10 w-full">
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center lg:w-full">
        <h2 className="text-lg leading-[24px] font-medium">Integrations</h2>
        <div>{search}</div>
      </div>

      {all && (
        <div className="space-y-12">
          <IntegrationsNodesSection
            title={popular.name}
            nodes={nodes.filter((n) =>
              n.relationships.tags.map((t) => t.id).includes(popular.id)
            )}
          />

          <IntegrationsNodesSection
            title={"Other"}
            nodes={nodes.filter(
              (n) => !n.relationships.tags.map((t) => t.id).includes(popular.id)
            )}
            groupSize={12}
          />
        </div>
      )}

      {!all && (
        <div className="space-y-12">
          {selected.map((section, index) => (
            <IntegrationsNodesSection
              //key={index}
              title={section.name}
              nodes={nodes.filter((n) =>
                n.relationships.tags.map((t) => t.id).includes(section.id)
              )}
            />
          ))}
        </div>
      )}

      <div>
        <RequestNew title="Request Integration" form_title="Request a new integration" />
      </div>
    </div>
  );
};

const Pagination = ({
  groupSize = 12,
  title = "Load More",
  items,
  className,
  id,
  children,
  ...props
}) => {
  const [numOfGroupdToShow, setNumOfGroupdToShow] = useState(1);

  const groups = Math.ceil(items.length / groupSize);

  const items0 = items.slice(0, numOfGroupdToShow * groupSize);

  return (
    <div>
      <div className={className} id={id}>
        {items0.map((item, index) => (
          <Component data={item} key={index} context="card" />
        ))}
      </div>

      <div>{children}</div>

      <div className="text-center mt-5">
        {numOfGroupdToShow < groups && (
          <button
            className="text-[#1B4DFF] font-medium leading-[20px]"
            onClick={() => setNumOfGroupdToShow(numOfGroupdToShow + 1)}
          >
            {title}
          </button>
        )}
      </div>
    </div>
  );
};

const IntegrationsNodesSection = ({ title, nodes, groupSize = 8 }) => {
  if (nodes.length === 0) return;

  return (
    <div className="text-left space-y-4">
      <h3 className="text-sm leading-[24px] font-normal text-[#88909F] uppercase">
        {title}
      </h3>

      <Pagination
        className="grid grid-cols-2 lg:grid-cols-4  gap-4"
        items={nodes}
        groupSize={groupSize}
      />
    </div>
  );
};


const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
   
    case "paragraph__card":
      comp = <ParagraphCard data={data} context={context} {...props} />;
      break;
    
    case "node__integration":
      comp = <NodeIntegration data={data} context={context} {...props} />;
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};


export default IntegrationsPage;

//export const Head = () => <title>Overview Page</title>

export const query = graphql`
  query integrationsPage($id: String!, $status: [Boolean!]!) {
    node: nodeIntegrations(id: { eq: $id }) {
      __typename
      id
      title

      metatag {
        attributes {
          content
          name
        }
        tag
      }

      relationships {
        hero: field_hero {
          ...ParagraphGenericBlock
        }
        hero_images: field_paragraph_repeter {
          __typename
          image: field_image {
            alt
            title
          }
        }
        sections: field_sections {
          __typename
          id
          name
        }
        cards_section: field_cards_section {
          ...ParagraphCardsContainer
        }
      }
    }
    terms: allTaxonomyTermIntegrationCategories(
      filter: { status: { in: $status } }
    ) {
      nodes {
        __typename
        name
        id
      }
    }
    nodes: allNodeIntegration(filter: { status: { in: $status } }) {
      nodes {
        ...NodeIntegration
      }
    }
  }
`;
